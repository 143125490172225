<template>
  <div class="token">
    <!--  token cover section-->
    <section v-show="!$store.state.app.isMobile" class="token-cover-section">
      <div class="token-cover">
        <v-menu
            bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon large v-bind="attrs"
                    v-on="on">mdi-dots-horizontal
            </v-icon>
          </template>

          <v-list>
            <v-list-item
                v-for="(item, index) in ellipsisItems"
                :key="index"
                @click="goPage(item.name)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="token-cover-info">
          <div class="token-cover-img">
            <img
                :src="`https://royal-io.imgix.net/${token.artworkAsset.filename}`"
                alt="token-cover">
          </div>
          <div class="token-cover-content">
            <p>{{ token.tier.edition.rightsAsset.title }}</p>
            <div class="token-cover-description">
              <div class="description-1">
                <p>{{ token.tier.edition.contributors[0].profile.name }}</p>
                <p>Artist</p>
              </div>
              <div class="description-2">
                <v-avatar size="40">
                  <img
                      :src=avatarImg
                      alt="John"
                  >
                </v-avatar>
                <div>
                  <p>{{ ownerName }}</p>
                  <p>Owner</p>
                </div>
              </div>
              <div class="description-3">
                <p>1/{{ token.tier.maxSupply }}</p>
                <p>Mint #</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--    token info section-->
    <section class="token-info-section">
      <div class="token-info-left-content">
        <img
            src="https://www.udiscovermusic.com/wp-content/uploads/2020/03/Justin-Bieber-My-World-2-album-cover-820.jpg"
            alt="token-cover">
        <div
            class="token-rating"
            :class="{'drop-rating-diamond': token.tier.type === 'DIAMOND', 'drop-rating-platinum' : token.tier.type === 'PLATINUM', 'drop-rating-gold' : token.tier.type === 'GOLD'}">
          {{ token.tier.type }}
        </div>
        <div class="token-title">
          <p>{{ token.tier.edition.rightsAsset.title }}</p>
          <p>{{ token.tier.edition.contributors[0].profile.name }}</p>
        </div>
        <div class="token-description">
          <div class="description-1">
            <p>OWNER</p>
            <div class="description-1-1">
              <v-avatar size="40">
                <img
                    :src=avatarImg
                    alt="John"
                >
              </v-avatar>
              <div class="description-owner">
                <p v-if="$route.params.ownerName.length !== 42">{{ reduceAddress(token.wallet.address) }}</p>
                <p>{{ ownerName }}</p>
              </div>
            </div>
          </div>
          <div class="description-2">
            <p>{{ token.tier.edition.rightsAsset.type }}</p>
            <p>{{ numberOfSongs }} songs</p>
          </div>
          <div class="description-3">
            <p>TOKEN NO.</p>
            <p>1/{{ token.tier.maxSupply }}</p>
          </div>
        </div>
        <hr/>
        <a :href="openSeaUrl" target="_blank">
        <span>VIEW ON OPENSEA</span>
          <v-icon small>mdi-arrow-right</v-icon>
        </a>
      </div>
      <div class="token-info-right-content">
        <div class="token-title">
          <p>TOKEN DETAILS</p>
          <v-menu
              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon large v-bind="attrs"
                      v-on="on">mdi-dots-horizontal
              </v-icon>
            </template>

            <v-list>
              <v-list-item
                  v-for="(item, index) in ellipsisItems"
                  :key="index"
                  @click="goPage(item.name)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="token-information">
          <div class="information">
            <p>Legal</p>
            <div class="information-1">
              <span>Token ID</span>
              <span>{{ reduceTokenId(token.tokenId) }}</span>
            </div>
            <div class="information-1">
              <span>Contract Address</span>
              <a
                  :href=token.tier.chainContract.network.explorers[0].url
                  target="_blank"
                  class="pointer">
                {{ reduceAddress(token.tier.chainContract.address) }}
              </a>
            </div>
            <div class="information-1">
              <span>Blockchain</span>
              <span>{{ token.tier.chainContract.network.name }}</span>
            </div>
            <div class="information-1">
              <span>Token Standard</span>
              <span>ERC-1155</span>
            </div>
            <div class="information-1">
              <span>Legal</span>
              <a href="#" class="pointer">Read Now</a>
            </div>
          </div>
          <div class="information">
            <p>Drop</p>
            <div class="information-1">
              <span>Drop Date</span>
              <span>{{ dropDate }}</span>
            </div>
            <div class="information-1">
              <span>Collaborators</span>
              <span class="pointer" @click="goPage('artist')">{{
                  token.tier.edition.contributors[0].profile.name
                }}</span>
            </div>
            <div class="information-1">
              <span>Edition</span>
              <span class="pointer" @click="goPage('edition')">{{ token.tier.edition.rightsAsset.title }}</span>
            </div>
          </div>
          <div class="information">
            <p>Financial</p>
            <div class="information-1">
              <span>Ownership</span>
              <span>{{ token.tier.edition.contributors[0].royaltyRightsPercentage }}%</span>
            </div>
            <div class="information-1">
              <span>Asset Sellers</span>
              <span class="pointer" @click="goPage('artist')">{{
                  token.tier.edition.contributors[0].profile.name
                }}</span>
            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'Token',
  components: {},
  data() {
    return {
      ellipsisItems: [
        {title: 'VISIT ARTIST PAGE', name: 'artist'},
        {title: 'VISIT EDITION PAGE', name: 'edition'},
      ],
      token: {},
      openSeaUrl: 'https://opensea.io/assets/matic/0x7c885c4bFd179fb59f1056FBea319D579A278075/2041694201525630780780247644590609268737',
    }
  },
  computed: {
    avatarImg() {
      return this.$route.params.ownerAvatarImg ? `https://royal-io.imgix.net/${this.$route.params.ownerAvatarImg}` : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ5ynbgJy2hzqlN4PKwb6-te9VvZ0yTx3sVQ&usqp=CAU'
    },
    ownerName() {
      return this.$route.params.ownerName.length === 42 ? this.reduceAddress(this.$route.params.ownerName) : `@${this.$route.params.ownerName}`
    },
    numberOfSongs() {
      return this.token.tier.edition.rightsAsset.childAssets.length > 0 ? this.token.tier.edition.rightsAsset.childAssets.length : 1
    },
    dropDate() {
      return dayjs(this.token.tier.edition.startTime).format('MMM D, YYYY')
    }
  },
  async mounted() {
    await this.tokenData()
  },
  methods: {
    async tokenData() {
      await this.$http.get(`/token/${this.$route.params.tokenId}`
      ).then(res => {
        this.token = res.data.token
      }).catch
      ((err) => {
        console.log(err)
      })
    },
    goPage(name) {
      this.$router.push({
        name: name,
        params: {
          artist: this.token.tier.edition.contributors[0].profile.name,
          edition: this.token.tier.edition.rightsAsset.title,
        }
      })
    },
    reduceAddress(value) {
      return value.slice(0, 6) + '...' + value.slice(38, 42)
    },
    reduceTokenId(value) {
      return value.slice(0, 5) + '...' + value.slice(35, 40)
    },
  }
}
</script>