<template>
  <div class="marketplace">
    <!--  featured section-->
    <section class="featured-section">
      <p>FEATURED</p>
      <v-carousel
          :show-arrows="false"
          hide-delimiter-background
          delimiter-icon="mdi-record"
          height="380"
      >
        <v-carousel-item
            v-for="(edition,i) in editionList"
            :key="i"
            :src="`https://royal-io.imgix.net/${edition.filename}`"
            @click="goPage('edition', i, edition.name)"
        >

          <div
              class="carousel-text"
          >
            <div>
              <p>EP</p>
              <p>{{ edition.name }}</p>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </section>

    <!--    recent drop section-->
    <section class="recent-drop-section">
      <div class="recent-drop-top">
        <p>RECENT DROP</p>
        <div class="drop-list">
          <v-select
              v-model="select"
              :items="dropList"
              label="SONGS"
              append-icon="mdi-menu-down"
              dense
              solo
              @change="onChange(select)"
          ></v-select>
        </div>
        <!--        <select class="drop-list" v-model="selected" data-title="AlL TIERS" @change="selectedList">-->
        <!--&lt;!&ndash;          <option&ndash;&gt;-->
        <!--&lt;!&ndash;              class="drop"&ndash;&gt;-->
        <!--&lt;!&ndash;              v-for="(drop, i) in dropList"&ndash;&gt;-->
        <!--&lt;!&ndash;              :key="i"&ndash;&gt;-->
        <!--&lt;!&ndash;              :value="drop.value"&ndash;&gt;-->
        <!--&lt;!&ndash;              :ref="`drop`+i"&ndash;&gt;-->
        <!--&lt;!&ndash;          >&ndash;&gt;-->
        <!--&lt;!&ndash;            {{ drop.text }}&ndash;&gt;-->
        <!--&lt;!&ndash;          </option>&ndash;&gt;-->
        <!--        </select>-->
      </div>
      <div class="recent-drops-cards">
        <card-drop
            v-for="(token, i) in tokensLoaded" :key="i"
            @click="goPage('token', i)"
            :artworkImg="token.node.artworkAsset.filename"
            :tier="token.node.tier.type"
            :avatarImg=" !token.node.wallet.user ? null : token.node.wallet.user.profiles[0].avatarAsset"
            :userName=" !token.node.wallet.user ? token.node.wallet.address : !token.node.wallet.user.profiles[0].avatarAsset ? token.node.wallet.address : token.node.wallet.user.profiles[0].slug"
            :editionTitle="token.node.tier.edition.rightsAsset.title"
            :editionType="token.node.tier.edition.rightsAsset.type"
            :maxSupply="token.node.tier.maxSupply"
            :artist="token.node.tier.edition.contributors[0].profile.name"
        />
      </div>
      <div class="load-more" v-if="length < tokens.length">
        <button @click="loadMore">LOAD MORE</button>
      </div>
    </section>
  </div>
</template>

<script>
import CardDrop from "@/components/Card/CardDrop";
import token from "@/views/Detail/Token";

export default {
  name: 'Marketplace',
  components: {
    CardDrop,
  },
  data() {
    return {
      editionList: [],
      tokens: [],
      resetTokens: [],
      length: 12,
      dropList: ["Underrated", "He's Not You", "Rare", "Ultra Black", "Waveform", "Worst Case"],
      select: '',
      selected: "0",
      ellipsisItems: [
        {title: 'VISIT ARTIST PAGE', name: 'artist'},
        {title: 'VISIT EDITION PAGE', name: 'edition'},
      ],
      offset: true,
    }
  },
  computed: {
    tokensLoaded() {
      return this.tokens.slice(0, this.length)
    }
  },
  async mounted() {
    await this.maketplaceData()
  },
  methods: {
    async maketplaceData() {
      await this.$http.get('/marketplace/data'
      ).then(res => {
        this.editionList = res.data.editionList
        this.tokens = res.data.data.tokens.edges
        this.resetTokens = res.data.data.tokens.edges
      }).catch
      ((err) => {
        console.log(err)
      })
    },
    // selectedList() {
    //   if (this.selected !== 0) {
    //     this.$refs.drop0[0].style.display = 'block';
    //   } else {
    //     this.$refs.drop0[0].style.display = 'none';
    //   }
    // },
    goPage(name, index, edition) {
      this.$router.push({
        name: name,
        params: {
          tokenId: this.tokens[index].node.royalId,
          edition: edition,
          ownerAvatarImg: !this.tokens[index].node.wallet.user ? null : !this.tokens[index].node.wallet.user.profiles[0].avatarAsset ? null : this.tokens[index].node.wallet.user.profiles[0].avatarAsset.filename  ,
          ownerName: !this.tokens[index].node.wallet.user ? this.tokens[index].node.wallet.address : !this.tokens[index].node.wallet.user.profiles[0].avatarAsset ? this.tokens[index].node.wallet.address : this.tokens[index].node.wallet.user.profiles[0].slug,
        }
      })
    },
    loadMore() {
      if (this.length < this.tokens.length)
        return this.length += 12
    },
    onChange(value) {
      this.tokens = this.resetTokens
      let result = this.tokens.filter(token => token.node.tier.edition.rightsAsset.title === value)
      this.tokens = []
      this.tokens.push.apply(this.tokens,result)
    }
  }
}
</script>